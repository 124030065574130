import { ThemeProvider } from "@material-ui/core/styles";
import { CssBaseline, Container, Typography } from "@material-ui/core";
import { Helmet } from "react-helmet";
import React from "react";
import { theme } from "../Theme";

export default function ProviderWrapper({ children }) {
  return (
    <>
      <Helmet>
        <link
          href="https://fonts.googleapis.com/css2?family=Fira+Sans:wght@300&display=swap"
          rel="stylesheet"
        />
      </Helmet>
      <CssBaseline>
        <Container>
          <Typography component="span">
            <ThemeProvider theme={theme}>{children}</ThemeProvider>
          </Typography>
        </Container>
      </CssBaseline>
    </>
  );
}
